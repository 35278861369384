// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["TLS_zGLFE", "f6jYWuY0o", "lLPTmA6W5"];

const variantClassNames = {f6jYWuY0o: "framer-v-8ylliw", lLPTmA6W5: "framer-v-1fcq8li", TLS_zGLFE: "framer-v-1xdaox4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 1, ease: [0.12, 0.23, 0.5, 1], type: "tween"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {End: "lLPTmA6W5", Initial: "TLS_zGLFE", Pulse: "f6jYWuY0o"}

const getProps = ({color, height, id, width, ...props}) => { return {...props, adPC0uzY1: color ?? props.adPC0uzY1 ?? "var(--token-7a28f2bc-a468-497f-91db-9c8c764b43d3, rgb(0, 153, 255)) /* {\"name\":\"Primary\"} */", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "TLS_zGLFE"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, adPC0uzY1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "TLS_zGLFE", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1286k1e = activeVariantCallback(async (...args) => {
await delay(() => setVariant("f6jYWuY0o"), 1000)
})

const onAppear19vuqfg = activeVariantCallback(async (...args) => {
await delay(() => setVariant("lLPTmA6W5"), 500)
})

const onAppears8nifs = activeVariantCallback(async (...args) => {
await delay(() => setVariant("TLS_zGLFE"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear1286k1e, f6jYWuY0o: onAppear19vuqfg, lLPTmA6W5: onAppears8nifs})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-aqgDr", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1xdaox4", className)} data-framer-name={"Initial"} data-highlight layoutDependency={layoutDependency} layoutId={"TLS_zGLFE"} ref={ref} style={{...style}} {...addPropertyOverrides({f6jYWuY0o: {"data-framer-name": "Pulse"}, lLPTmA6W5: {"data-framer-name": "End"}}, baseVariant, gestureVariant)}><motion.div className={"framer-kz3coa"} layoutDependency={layoutDependency} layoutId={"Raa5yMYns"} style={{backgroundColor: adPC0uzY1, borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", opacity: 0}} transformTemplate={transformTemplate} variants={{f6jYWuY0o: {opacity: 0.3}}} {...addPropertyOverrides({f6jYWuY0o: {transformTemplate: undefined}, lLPTmA6W5: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/><motion.div className={"framer-1gm5fdz"} layoutDependency={layoutDependency} layoutId={"MwzS9a43p"} style={{backgroundColor: adPC0uzY1, borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} transformTemplate={transformTemplate}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-aqgDr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-aqgDr .framer-rlyjhy { display: block; }", ".framer-aqgDr .framer-1xdaox4 { height: 18px; overflow: hidden; position: relative; width: 18px; }", ".framer-aqgDr .framer-kz3coa { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 8px); left: 50%; overflow: visible; position: absolute; top: 50%; width: 44%; }", ".framer-aqgDr .framer-1gm5fdz { aspect-ratio: 1 / 1; flex: none; height: 44%; left: 50%; overflow: visible; position: absolute; top: 50%; width: var(--framer-aspect-ratio-supported, 8px); }", ".framer-aqgDr.framer-v-8ylliw .framer-1xdaox4, .framer-aqgDr.framer-v-1fcq8li .framer-1xdaox4 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 18px); }", ".framer-aqgDr.framer-v-8ylliw .framer-kz3coa, .framer-aqgDr.framer-v-1fcq8li .framer-kz3coa { height: var(--framer-aspect-ratio-supported, 18px); left: 0px; right: 0px; top: 0px; width: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 18
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"f6jYWuY0o":{"layout":["fixed","fixed"]},"lLPTmA6W5":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"adPC0uzY1":"color"}
 * @framerImmutableVariables false
 */
const FramerFmFXslTUM: React.ComponentType<Props> = withCSS(Component, css, "framer-aqgDr") as typeof Component;
export default FramerFmFXslTUM;

FramerFmFXslTUM.displayName = "Pulse Indicator";

FramerFmFXslTUM.defaultProps = {height: 18, width: 18};

addPropertyControls(FramerFmFXslTUM, {variant: {options: ["TLS_zGLFE", "f6jYWuY0o", "lLPTmA6W5"], optionTitles: ["Initial", "Pulse", "End"], title: "Variant", type: ControlType.Enum}, adPC0uzY1: {defaultValue: "var(--token-7a28f2bc-a468-497f-91db-9c8c764b43d3, rgb(0, 153, 255)) /* {\"name\":\"Primary\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerFmFXslTUM, [])